export const StudentApplication = ({ application }) => {
  return (
    <>
      <form className="students_forms" action="#" method="post">
        <h5 className="students_forms_title mt-3 mb-0">Student Info</h5>
        <div className="students_forms_height">
          <div className="students_forms_box1 mt-2 align-items-center">
            <h6 className="students_forms_boxTitle">Student name</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Surname
                <input
                  placeholder="John"
                  className="students_form_inputs"
                  type="text"
                  name="surname"
                  value={application.surname || ""}
                  disabled
                />
              </label>
              <label className="students_forms_label">
                Name
                <input
                  placeholder="Doe"
                  className="students_form_inputs"
                  type="text"
                  disabled
                  value={application.name || ""}
                  name="name"
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Student age</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Age
                <input
                  placeholder="24"
                  className="students_form_inputs"
                  type="number"
                  disabled
                  value={application.age || 0}
                  name="age"
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Phone number</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Phone number
                <input
                  placeholder="+998(88)8882211"
                  className="students_form_inputs"
                  type="tel"
                  name="phone"
                  minLength="9"
                  disabled
                  value={application.phone || ""}
                  maxLength="14"
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Telegram username</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Username
                <input
                  placeholder="@telegram"
                  className="students_form_inputs"
                  type="text"
                  disabled
                  value={application.tgUsername || ""}
                  name="telegram"
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Degree</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Degree
                <input
                  placeholder="degree"
                  className="students_form_inputs"
                  type="text"
                  disabled
                  value={application.degree || ""}
                  name="degree"
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Country</h6>
            <div className="students_forms_inputBox justify-content-start">
              <label className="students_forms_label">
                Country
                <input
                  placeholder="country"
                  className="students_form_inputs"
                  type="text"
                  disabled
                  value={application.country || ""}
                  name="Country"
                />
              </label>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
