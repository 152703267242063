import React, {useEffect, useState} from "react";
import FileDow from "../../../Img/file-dow.svg";
import {useApiServer} from "../ApiServer";


const allowedFileTypes = {
    /*-passport/ID card => pdf/JPEG
    -IELTS/Duolingo => pdf/JPEG
-Diploma => pdf/JPEG
-Recommendation => pdf/JPEG/word
-Personal Statement => pdf/Word
    -CV => pdf/JPEG/Word
-Photo => JPEG
-Ds info box => WORD
    -Other => JPEG/pdf/word*/
    passport1: ['application/pdf', 'image/jpeg'],
    passport2: ['application/pdf', 'image/jpeg'],
    passport3: ['application/pdf', 'image/jpeg'],
    languageCertificate1: ['application/pdf', 'image/jpeg'],
    languageCertificate2: ['application/pdf', 'image/jpeg'],
    languageCertificate3: ['application/pdf', 'image/jpeg'],
    diploma1: ['application/pdf', 'image/jpeg'],
    diploma2: ['application/pdf', 'image/jpeg'],
    diploma3: ['application/pdf', 'image/jpeg'],
    diploma: ['application/pdf', 'image/jpeg'],
    recommendation1: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    recommendation2: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    recommendation3: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    personalStatement1: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    personalStatement2: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    personalStatement3: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    cv: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    photos: ['image/jpeg'],
    dsInfoBox: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    other1: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    other2: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    other3: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

export const StudentInfoDocuments = ({
                                         detail,
                                         setDetail,
                                         role,
                                         onSave,
                                         isHiddenBtn,
                                         requiredFields,
    setAlert
                                     }) => {
    const {delData, postFile, putFile, downloadData} = useApiServer();
    const [isUpload, setIsUpload] = useState(false);
    const [isDelete, setIsDelete] = useState(false);


    useEffect(() => {
        const initial = async () => {
            await setIsUpload(
                (role === "ROLE_ADMIN")
                || (role === "ROLE_OPERATOR" && detail.consultantId)
                || detail.id == null
            )
            await setIsDelete(
                (role === "ROLE_ADMIN")
                || (role === "ROLE_OPERATOR" && detail.consultantId)
            )
        }

        initial()
    }, []);

    const handleOnChange = async (e, prop) => {
        isHiddenBtn(true);
        const file = e.target.files[0];
        const fileName = file.name.split('.')[0];

        if (!allowedFileTypes[prop].includes(file.type)) {
            alert('Invalid file type. Please upload a valid file.');
            return;
        }

        const value = detail[prop] || null;
        if (value) {
            return await putFile(file, fileName, value)
                .then(async (r) => {
                    setDetail((prevDetail) => ({
                        ...prevDetail,
                        [prop]: r,
                    }));
                    await onSave({...detail, [prop]: value})
                    isHiddenBtn(false);
                })
                .catch(() => setAlert({status: "error", message: "Not found data"}));
        } else {
            return await postFile(file, fileName)
                .then(async (r) => {
                    setDetail((prevDetail) => ({
                        ...prevDetail,
                        [prop]: r,
                    }))
                    await onSave({...detail, [prop]: r})
                    isHiddenBtn(false);
                })
                .catch(() => setAlert({status: "error", message: "Not found data"}));
        }
    };

    const handleOnDelete = async (e, prop) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDelete(true)

        // Show the hidden button
        isHiddenBtn(true);

        const value = detail[prop] || null;
        if (value) {
            try {

                // Perform the delete operation
                await delData(`attach/delete/${value}`)
                    .then(async () => {
                            await onSave({...detail, [prop]: null})
                            await setDetail({...detail, [prop]: null})
                        }
                    )
                    .catch(() => setAlert({status: "error", message: "Not found data"}));

            } catch (error) {
                setAlert({status: "error", message: "Not found data"})
            } finally {
                // Hide the button
                isHiddenBtn(false);
                setIsDelete(false);
            }
        } else {
            // Hide the button if there is no value
            isHiddenBtn(false);
            setIsDelete(false);
        }
    }

    const handleOnDownload = async (e, prop) => {


        const value = detail[prop] || null;
        if (value) {
            await downloadData(value)
                .catch(() => setAlert({status: "error", message: "Not found data"}));
        }
    };

    return (
        <form className="students_forms" action="#" method="post">
            <h5 className="students_forms_title mt-3 mb-0">Documents</h5>
            <div className="students_forms_height">
                {/* Passport */}
                <div className="students_forms_box1 mt-2 d-flex">
                    <h6 className="students_forms_boxTitle">
                        Passport/ID card
                        <span style={{color: "red", fontSize: "12px"}}>{(requiredFields && !(detail.passport1 || detail.passport2 || detail.passport3)) && " (upload required file)"}</span>
                    </h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`passport${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`passportFileInput${num}`}
                                        className="file-input"
                                        onChange={(e) => handleOnChange(e, `passport${num}`)}
                                    />
                                    <label
                                        htmlFor={`passportFileInput${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `passport${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`passport${num}`] ? "auto" : "none"
                                        }}

                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) => handleOnDelete(e, `passport${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`passport${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* IELTS/Duolingo Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">
                        IELTS/Duolingo
                        <span style={{
                            color: "red",
                            fontSize: "12px"
                        }}>{(requiredFields && !(detail.languageCertificate1 || detail.languageCertificate2 || detail.languageCertificate3)) && " (upload required file)"}</span>
                    </h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`languageCertificate${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`languageCertificate${num}`}
                                        className="file-input"
                                        onChange={(e) =>
                                            handleOnChange(e, `languageCertificate${num}`)
                                        }
                                    />
                                    <label
                                        htmlFor={`languageCertificate${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) =>
                                            handleOnDownload(e, `languageCertificate${num}`)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`languageCertificate${num}`] ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) =>
                                            handleOnDelete(e, `languageCertificate${num}`)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`languageCertificate${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Diploma Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">
                        Diploma
                        <span style={{
                            color: "red",
                            fontSize: "12px"
                        }}>{(requiredFields && !(detail.diploma1 || detail.diploma2 || detail.diploma3)) && " (upload required file)"}</span>
                    </h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`diploma${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`diploma${num}`}
                                        className="file-input"
                                        onChange={(e) => handleOnChange(e, `diploma${num}`)}
                                    />
                                    <label
                                        htmlFor={`diploma${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `diploma${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`diploma${num}`] ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) => handleOnDelete(e, `diploma${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`diploma${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Recommendation Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">Recommendation</h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg, .word</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`recommendation${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`recommendation${num}`}
                                        className="file-input"
                                        onChange={(e) => handleOnChange(e, `recommendation${num}`)}
                                    />
                                    <label
                                        htmlFor={`recommendation${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `recommendation${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`recommendation${num}`] ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) => handleOnDelete(e, `recommendation${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`recommendation${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Personal Statement Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">Personal Statement</h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg, .word</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`personalStatement${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`personalStatement${num}`}
                                        className="file-input"
                                        onChange={(e) =>
                                            handleOnChange(e, `personalStatement${num}`)
                                        }
                                    />
                                    <label htmlFor={`personalStatement${num}`} className="file-label"
                                           style={{pointerEvents: isUpload ? "none" : "auto"}}>
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `personalStatement${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`personalStatement${num}`] ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) =>
                                            handleOnDelete(e, `personalStatement${num}`)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`personalStatement${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* CV Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">CV</h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        <div>
                            <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg, .word</div>
                            <div className="file-input-container">
                            <input
                                    type="text"
                                    value={detail.cv || ""}
                                    readOnly
                                    className="file-name"
                                    placeholder="download"
                                />
                                <input
                                    type="file"
                                    id="cv"
                                    className="file-input"
                                    onChange={(e) => handleOnChange(e, "cv")}
                                />
                                <label htmlFor="cv" className="file-label"
                                       style={{pointerEvents: isUpload ? "none" : "auto"}}>
                                    <img
                                        style={{width: "20px"}}
                                        className="file-icon"
                                        src={FileDow}
                                        alt="File Icon"
                                    />
                                </label>
                                <div
                                    className="file-label-down"
                                    onClick={(e) => handleOnDownload(e, "cv")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`cv`] ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                            stroke="#344054"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className="file-label-svg"
                                    onClick={(e) => handleOnDelete(e, "cv")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`cv`] && !isDelete ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        className="file-icon"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Photo Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">Photo</h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        <div>
                            <div style={{fontSize: "13px"}}>Required type: .jpeg</div>
                            <div className="file-input-container">
                            <input
                                    type="text"
                                    value={detail.photos || ""}
                                    readOnly
                                    className="file-name"
                                    placeholder="download"
                                />
                                <input
                                    type="file"
                                    id="photos"
                                    className="file-input"
                                    onChange={(e) => handleOnChange(e, "photos")}
                                />
                                <label htmlFor="photos" className="file-label"
                                       style={{pointerEvents: isUpload ? "none" : "auto"}}>
                                    <img
                                        style={{width: "20px"}}
                                        className="file-icon"
                                        src={FileDow}
                                        alt="File Icon"
                                    />
                                </label>
                                <div
                                    className="file-label-down"
                                    onClick={(e) => handleOnDownload(e, "photos")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`photos`] ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                            stroke="#344054"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className="file-label-svg"
                                    onClick={(e) => handleOnDelete(e, "photos")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`photos`] && !isDelete ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        className="file-icon"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* DS Info Box Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">
                        DS info box
                        <span style={{
                            color: "red",
                            fontSize: "12px"
                        }}>{(requiredFields && !(detail.dsInfoBox)) && " (upload required file)"}</span>
                    </h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        <div>
                        <div style={{fontSize: "13px"}}>Required type: .word</div>
                            <div className="file-input-container">
                            <input
                                    type="text"
                                    value={detail.dsInfoBox || ""}
                                    readOnly
                                    className="file-name"
                                    placeholder="download"
                                />
                                <input
                                    type="file"
                                    id="dsInfoBox"
                                    className="file-input"
                                    onChange={(e) => handleOnChange(e, "dsInfoBox")}
                                />
                                <label htmlFor="dsInfoBox" className="file-label"
                                       style={{pointerEvents: isUpload ? "none" : "auto"}}>
                                    <img
                                        style={{width: "20px"}}
                                        className="file-icon"
                                        src={FileDow}
                                        alt="File Icon"
                                    />
                                </label>
                                <div
                                    className="file-label-svg"
                                    onClick={(e) => handleOnDownload(e, "dsInfoBox")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`dsInfoBox`] ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                            stroke="#344054"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className="file-label-down"
                                    onClick={(e) => handleOnDelete(e, "dsInfoBox")}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: detail[`dsInfoBox`] && !isDelete ? "auto" : "none"
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        className="file-icon"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Other Section */}
                <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                    <h6 className="students_forms_boxTitle">Other</h6>
                    <div className="students_forms_inputBox justify-content-start d-flex flex-wrap wrapper">
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div style={{fontSize: "13px"}}>Required type: .pdf, .jpeg, .word</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`other${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`other${num}`}
                                        className="file-input"
                                        onChange={(e) => handleOnChange(e, `other${num}`)}
                                    />
                                    <label
                                        htmlFor={`other${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `other${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`other${num}`] ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) => handleOnDelete(e, `other${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`other${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
};
