import { useRef } from "react";

export const ModalX = ({ modal, setModal, children, student }) => {
  const elOverlay = useRef();

  return (
      <div
          ref={elOverlay}
          // onClick={closeModal}
          className={`modal_overlay position-fixed top-0 start-0 w-100 h-100 pt-2 pb-2 d-flex align-items-center justify-content-center ${
              !modal && `d-none`
          }`}
      >
        <div className="modal_overlay_main w-auto mh-90 overflow-auto">
          <div className="modal_overlay_header">
            <h3 className="mt-0 mb-0">
              {student.name ? student.name : ""}
              {" "}
              {student.surname ? student.surname : ""}
              {student.name === null && student.surname === null ? "Student info" : ""}
            </h3>
            <button onClick={() => setModal()} className="modal_overlay_X">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
              >
                <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="modal_content px-5">{children}</div>
        </div>
        /
      </div>
  );
};
