import React, {useEffect, useRef, useState} from "react";
import Board, {moveCard} from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import "./KanbanList.scss";
import {useApiServer} from "../../pages/General/ApiServer";

const initialBoard = {
    columns: [],
};

export const KanbanList = ({dataAll, isOpen, handleApplicationId, searchTerm}) => {
    const {putData} = useApiServer();
    const [text] = useState("Note: ");
    const textareaRef = useRef(null);

    const [controlledBoard, setBoard] = useState(initialBoard);

    const sendReqForChangePosition = (oldC, newC, appId, oldP, newP) => {
        const data = {
            oldCanbanId: oldC,
            newCanbanId: newC,
            applicationId: appId,
            oldPosition: oldP,
            newPosition: newP,
        };

        putData("applications/change-position", data).then((r) => console.log(r));
    };

    useEffect(() => {
        if (dataAll && Array.isArray(dataAll)) {
            const formattedBoard = {
                columns: dataAll.map((column) => ({
                    id: column.id,
                    title: column.title,
                    backgroundColor: column.backgroundColor,
                    cards: column.cards.map((card) => ({
                        id: card.id,
                        title: card.title,
                        description: card.description,
                        comment: card.comment,
                    })),
                })),
            };
            setBoard(formattedBoard);
        }
    }, [dataAll]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [text]);

    function handleCardMove(_card, source, destination) {
        sendReqForChangePosition(
            source.fromColumnId,
            destination.toColumnId,
            _card.id,
            source.fromPosition,
            destination.toPosition
        );
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
    }


    const filteredBoard = {
        columns: controlledBoard.columns.map((column) => ({
            ...column,
            cards: column.cards.filter(
                (card) =>
                    card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    card.description.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        })),
    };

    const CustomCard = ({card}) => {
        const [inputVal, setInputVal] = useState(card.comment || "");
        const [showInput, setShowInput] = useState(false);

        const inputRef = useRef(null);

        const handleChange = (e) => {
            console.log(e.target.value);
            setInputVal(e.target.value);
        };

        const handleSubmit = async (event) => {
            event.preventDefault();

            const data = {
                id: card.id,
                comment: inputVal,
            };
            try {
                await putData(`applications/update-comment`, data).then((r) =>
                    console.log(r)
                );

                const updatedBoard = {...controlledBoard};
                updatedBoard.columns = updatedBoard.columns.map((column) => {
                    column.cards = column.cards.map((c) => {
                        if (c.id === card.id) {
                            return {...c, comment: inputVal};
                        }
                        return c;
                    });
                    return column;
                });
                setBoard(updatedBoard);

                setShowInput(false);
            } catch (error) {
                console.error("Error sending comment:", error);
            }
        };

        const toggleInput = () => {
            setShowInput(!showInput);
        };

        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowInput(false);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                setShowInput(false);
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
                document.removeEventListener("keydown", handleKeyDown);
            };
        }, []);

        return (
            <div
                className="custom-card"
                style={{
                    cursor: "pointer",
                    width: isOpen ? "255px" : "310px",
                    display: "flex",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        width: "170%",
                    }}
                >
                    <h4
                        onClick={() => {
                            handleApplicationId(card.id);
                        }}
                        className="card__title"
                    >
                        {card.title || "-- -- --"}
                    </h4>
                    <p
                        onClick={() => {
                            handleApplicationId(card.id);
                        }}
                        className="card__text"
                    >
                        {card.description || "-- -- --"}
                    </p>
                </div>
                <div
                    className="input-group mb-3"
                    style={{justifyContent: "end", alignContent: "center"}}
                    onClick={toggleInput}
                >
                    <span
                        className="input-group-text js-spanjon"
                        style={{
                            border: "none",
                            background: "none",
                            height: "10px",
                            cursor: "pointer",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className="imgg"
                        >
                            <path
                                d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C9.00238 17.5 8.05025 17.3052 7.17958 16.9516C7.01294 16.8839 6.92962 16.8501 6.86227 16.835C6.79638 16.8202 6.74763 16.8148 6.68011 16.8148C6.61109 16.8148 6.53591 16.8273 6.38554 16.8524L3.42063 17.3466C3.11015 17.3983 2.95491 17.4242 2.84265 17.376C2.7444 17.3339 2.66611 17.2556 2.62397 17.1573C2.57582 17.0451 2.60169 16.8898 2.65344 16.5794L3.14759 13.6145C3.17265 13.4641 3.18518 13.3889 3.18517 13.3199C3.18516 13.2524 3.17976 13.2036 3.165 13.1377C3.1499 13.0704 3.11606 12.9871 3.04839 12.8204C2.69478 11.9497 2.5 10.9976 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                stroke="#94969C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                {showInput && (
                    <div
                        ref={inputRef}
                        className={`form-floating ${showInput ? "textarea-animation" : ""}`}
                        style={{
                            width: "238px",
                            color: "white",
                            marginTop: "5px",
                            borderRadius: "5px",
                            position: "absolute",
                            zIndex: 1000,
                            opacity: 1,
                            left: 0, // Ensure the textarea is aligned properly
                        }}
                    >
                        <svg
                            className="tri"
                            width={10}
                            height={10}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 9"
                            fill="none"
                        >
                            <path
                                d="M1.92894 8.51471C1.03803 8.51471 0.591868 7.43757 1.22183 6.8076L7.29289 0.736529C7.68342 0.346004 8.31658 0.346005 8.70711 0.736529L14.7782 6.8076C15.4081 7.43757 14.962 8.51471 14.0711 8.51471L1.92894 8.51471Z"
                                fill="#101828"
                            />
                        </svg>
                        <form onSubmit={handleSubmit}>
                            <textarea
                                ref={textareaRef}
                                rows={4}
                                cols={4}
                                onChange={handleChange}
                                type="text"
                                className="form-control textarea__form"
                                value={inputVal}
                                placeholder="Write a comment"
                                style={{
                                    width: "100%",
                                    padding: "12px",
                                    background: "#101828",
                                    color: "white",
                                    border: "none",
                                    resize: "none",
                                    position: "absolute",
                                    left: "0px", // Ensure the textarea is aligned properly
                                    top: "32px",
                                }}
                            />
                            <button className="btn__ok" type="submit">
                                OK
                            </button>
                        </form>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Board
            className="kanban_div"
            onCardDragEnd={handleCardMove}
            disableColumnDrag
            renderCard={(card) => <CustomCard card={card}/>}
            renderColumnHeader={({title}) => (
                <>
                    <div>
                        <div className="title__kanba">{title.split("?")[1]}</div>
                        <div className="title__kanba">{title.split("?")[0]}</div>
                    </div>
                </>
            )}
        >
            {filteredBoard}
        </Board>

    );
};
