import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import {KanbanList} from "../../../components/KanbanList/KanbanList";
import "./Kanban.scss";
import {useApiServer} from "../ApiServer";
import StudentInfoModal from "../StudentInfoModal/StudentInfoModal";

export const Kanban = ({isOpen, setAlert}) => {
    const {getData} = useApiServer();
    const [dataAll, setDataAll] = useState();
    const [modal, setModal] = useState(false)
    const [applicationId, setApplicationId] = useState()
    const [studentId, setStudentId] = useState()
    const [role, setRole] = useState("ROLE_OPERATOR")
    const [searchTerm, setSearchTerm] = useState("");

    const closeModalFunc = () => {
        setModal(false)
        setStudentId(null)
        setApplicationId(null)
    }

    const handleApplicationId = (id = null) => {

        if (id) {
            setApplicationId(id)
        }
        setModal(true)
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const x = () => {

    }

    useEffect(() => {
        getData("canban/all")
            .then((r) => setDataAll(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));

        setRole(localStorage.getItem("role"))
    }, []);
    return (
        <>
            <div
                style={{width: isOpen ? "81%" : "81%"}}
                className="containerH kanba__box"
            >
                <div className="home_header">
                    <h2>Students</h2>
                    <div>
                        <label>
                            <img src={Search} alt={"Search"}/>
                            <input placeholder="Search..."
                                   value={searchTerm}
                                   onChange={handleSearch}
                                   className="search-input"
                            />
                        </label>
                    </div>
                </div>
                <div className="home_span"></div>
                <div style={{cursor: "pointer"}} className="mt-3 d-flex">
                    <KanbanList
                        dataAll={dataAll}
                        isOpen={isOpen}
                        handleApplicationId={handleApplicationId}
                        searchTerm={searchTerm}
                    />
                </div>
            </div>
            <StudentInfoModal
                modal={modal}
                setModal={closeModalFunc}
                role={role}
                getAll={x}
                applicationId={applicationId}
                studentId={studentId}
            />
        </>
    );
};
